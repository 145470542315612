import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import { TextLink } from '@openameba/spindle-ui/TextLink';
import { Openblank } from '@openameba/spindle-ui/Icon';
import * as style from '../../../../node_modules/@openameba/spindle-ui/TextLink/TextLink.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="ブランドガイドライン - Amebaブランド" mdxType="SEO" />
    <PageTitle title="ブランドガイドライン" enTitle="Brand Guidelines" mdxType="PageTitle" />
    <h2 {...{
      "id": "ブランドガイドラインについて",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%82%AC%E3%82%A4%E3%83%89%E3%83%A9%E3%82%A4%E3%83%B3%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
        "aria-label": "ブランドガイドラインについて permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ブランドガイドラインについて`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/06c887d410ca55de4600081b407d2236/21b4d/brand-guidelines-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/06c887d410ca55de4600081b407d2236/2aaaf/brand-guidelines-index.webp 160w", "/static/06c887d410ca55de4600081b407d2236/85e47/brand-guidelines-index.webp 320w", "/static/06c887d410ca55de4600081b407d2236/75198/brand-guidelines-index.webp 640w", "/static/06c887d410ca55de4600081b407d2236/691bc/brand-guidelines-index.webp 960w", "/static/06c887d410ca55de4600081b407d2236/223e5/brand-guidelines-index.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/06c887d410ca55de4600081b407d2236/69538/brand-guidelines-index.png 160w", "/static/06c887d410ca55de4600081b407d2236/72799/brand-guidelines-index.png 320w", "/static/06c887d410ca55de4600081b407d2236/6af66/brand-guidelines-index.png 640w", "/static/06c887d410ca55de4600081b407d2236/d9199/brand-guidelines-index.png 960w", "/static/06c887d410ca55de4600081b407d2236/21b4d/brand-guidelines-index.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/06c887d410ca55de4600081b407d2236/6af66/brand-guidelines-index.png",
              "alt": "「Brand Guidelines」と書かれた吹き出しを中心に、6人のイラストが描かれている",
              "title": "「Brand Guidelines」と書かれた吹き出しを中心に、6人のイラストが描かれている",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "ブランドガイドラインの位置付け",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%82%AC%E3%82%A4%E3%83%89%E3%83%A9%E3%82%A4%E3%83%B3%E3%81%AE%E4%BD%8D%E7%BD%AE%E4%BB%98%E3%81%91",
        "aria-label": "ブランドガイドラインの位置付け permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ブランドガイドラインの位置付け`}</h3>
    <p>{`Amebaブランドガイドラインは、「サービスとしてのAmeba」のブランドについて規定しています。本ガイドラインがAmeba全体のブランディングの核となり、すべてのプロダクトやサービスに対して上位の指針となります。
一方で、各プロダクト、サービスで本ガイドラインには書かれていない個別ルールが必要になる場合があります。サービスの個性を踏まえた個別のガイドラインを作る場合は、本ガイドラインとの整合性を保つように留意してください。`}</p>
    <figure>
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ade276ffec267d9437266c429e49ff2f/21b4d/brand-guidelines-positioning.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.625%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ade276ffec267d9437266c429e49ff2f/2aaaf/brand-guidelines-positioning.webp 160w", "/static/ade276ffec267d9437266c429e49ff2f/85e47/brand-guidelines-positioning.webp 320w", "/static/ade276ffec267d9437266c429e49ff2f/75198/brand-guidelines-positioning.webp 640w", "/static/ade276ffec267d9437266c429e49ff2f/691bc/brand-guidelines-positioning.webp 960w", "/static/ade276ffec267d9437266c429e49ff2f/223e5/brand-guidelines-positioning.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ade276ffec267d9437266c429e49ff2f/69538/brand-guidelines-positioning.png 160w", "/static/ade276ffec267d9437266c429e49ff2f/72799/brand-guidelines-positioning.png 320w", "/static/ade276ffec267d9437266c429e49ff2f/6af66/brand-guidelines-positioning.png 640w", "/static/ade276ffec267d9437266c429e49ff2f/d9199/brand-guidelines-positioning.png 960w", "/static/ade276ffec267d9437266c429e49ff2f/21b4d/brand-guidelines-positioning.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/ade276ffec267d9437266c429e49ff2f/6af66/brand-guidelines-positioning.png",
                "alt": "ブランドガイドラインの位置付けを説明するピラミッド図",
                "title": "ブランドガイドラインの位置付けを説明するピラミッド図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
  <figcaption className="visually-hidden">ピラミッド図は4層からなり、最下層である4層目が「Service & Product」、3層目が「Design System & Guidelines」、2層目が「Brand Identity Concept」、最上層である1層目が「Vision Mission」と書かれている。1層目と2層目が塗りつぶされており、この図で特筆している層であることが分かる。4層目「Service & Product」は、ユーザーとのタッチポイントとなるサービスやプロダクトである。3層目「Design System & Guidelines」は、本ガイドラインを踏まえたブランド・プロダクトのガイドラインにあたる。2層目「Brand Identity Concept」は、あらゆるタッチポイントにおけるサービスブランドの特徴を表すものである。1層目「Vision Mission」は、サービスブランドの核となる理念である。</figcaption>
    </figure>
    <h2 {...{
      "id": "amebaブログ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ameba%E3%83%96%E3%83%AD%E3%82%B0",
        "aria-label": "amebaブログ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Amebaブログ`}</h2>
    <p>{`
  `}<img parentName="p" {...{
        "className": "theme--light",
        "src": "/images/logo-amebablog.svg",
        "alt": "Amebaブログのロゴ",
        "height": 73,
        "width": 360
      }}></img>{`
  `}<img parentName="p" {...{
        "className": "theme--dark",
        "src": "/images/logo-amebablog-inverse.svg",
        "alt": "Amebaブログのロゴ",
        "height": 73,
        "width": 360
      }}></img>{`
`}</p>
    {
      /* textlint-disable @openameba/ameba/ja-space-between-half-and-full-width */
    }
    <p>{`「Amebaブログ Brand Guidelines」は、制作者、広告主、取引先などあらゆる関係者との接点において私たちの誰もが一貫したコミュニケーションを行い、“Amebaらしさ”を共に創り上げるための指針です。`}</p>
    {
      /* textlint-enable */
    }
    <p className="layout-content-anchor">
  <TextLink href="https://speakerdeck.com/spindle/amebablog-brand-guidelines" icon={<Openblank aria-label="外部サイト" mdxType="Openblank" />} iconPosition="end" rel="noopener noreferrer" target="_blank" variant="subtle" mdxType="TextLink">Amebaブログ Brand Guidelinesを見る</TextLink>
    </p>
    <h2 {...{
      "id": "pigg",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pigg",
        "aria-label": "pigg permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Pigg`}</h2>
    <p>{`
  `}<img parentName="p" {...{
        "className": "theme--light",
        "src": "/images/logo-pigg.svg",
        "alt": "Piggのロゴ",
        "height": 71,
        "width": 197
      }}></img>{`
  `}<img parentName="p" {...{
        "className": "theme--dark",
        "src": "/images/logo-pigg-inverse.svg",
        "alt": "Piggのロゴ",
        "height": 71,
        "width": 197
      }}></img>{`
`}</p>
    <p>{`準備中です。`}</p>
    <h2 {...{
      "id": "amebaマンガ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ameba%E3%83%9E%E3%83%B3%E3%82%AC",
        "aria-label": "amebaマンガ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Amebaマンガ`}</h2>
    <p>{`
  `}<img parentName="p" {...{
        "className": "theme--light",
        "src": "/images/logo-amebamanga.svg",
        "alt": "Amebaマンガのロゴ",
        "height": 97,
        "width": 426
      }}></img>{`
  `}<img parentName="p" {...{
        "className": "theme--dark",
        "src": "/images/logo-amebamanga-inverse.svg",
        "alt": "Amebaマンガのロゴ",
        "height": 97,
        "width": 426
      }}></img>{`
`}</p>
    <p>{`準備中です。`}</p>
    <h2 {...{
      "id": "ドットマネー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%89%E3%83%83%E3%83%88%E3%83%9E%E3%83%8D%E3%83%BC",
        "aria-label": "ドットマネー permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ドットマネー`}</h2>
    <p>{`
  `}<img parentName="p" {...{
        "className": "theme--light",
        "src": "/images/logo-dotmoney.svg",
        "alt": "ドットマネーのロゴ",
        "height": 63,
        "width": 466
      }}></img>{`
  `}<img parentName="p" {...{
        "className": "theme--dark",
        "src": "/images/logo-dotmoney-inverse.svg",
        "alt": "ドットマネーのロゴ",
        "height": 63,
        "width": 466
      }}></img>{`
`}</p>
    <p>{`準備中です。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      